// Core

body {
  .app-sidebar-heading {
    padding: $spacer $spacer ($spacer / 1.1);
    font-weight: bold;
    color: $blue;
    text-transform: uppercase;
    font-size: $font-size-sm;
    white-space: nowrap;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  // active or hove state
  .active-item .app-sidebar-icon path, .app-sidebar-button:hover .app-sidebar-icon path
  {
    fill:#5383ff;
  }

  .active-item .app-sidebar-icon rect, .active-item .app-sidebar-icon rect ~ path,
  .app-sidebar-button:hover .app-sidebar-icon rect,
  .app-sidebar-button:hover .app-sidebar-icon rect ~ path
  {
    fill:none;
    stroke:#5383ff;
  }

  .app-sidebar-icon path
  {
    fill:#737373;
  }

  .app-sidebar-icon rect, .app-sidebar-icon rect ~ path
  {
    fill:none;
    stroke:#737373;
  }

  // Sidebar menu item
  .second-app-sidebar-item {
    display: block;
    padding: 2px ($spacer) 1px 0;
  }

  .Mui-selected .sidebar-icon path
  {
    stroke: #263055 !important;
  }

  .Mui-disabled .sidebar-icon path {
    stroke: rgba(0, 0, 0, 0.26);
  }

  .sidebar-icon path {
    //stroke: #263055;
    stroke:#263055;
  }

  .Mui-selected .sidebar-icon-fill path
  {
    fill: #263055 !important;
  }

  .Mui-disabled .sidebar-icon-fill path {
    fill: rgba(0, 0, 0, 0.26);
  }

  .sidebar-icon-fill path {
    //stroke: #263055;
    fill:#263055;
  }

  .MuiListItem-root.Mui-selected > .second-app-sidebar-button
  {
    background-color:rgba(0, 0, 0, 0.08);
  }

  .MuiListItem-root.Mui-selected.second-app-sidebar-item
  {
    background-color:initial;
  }

  .second-app-sidebar-button {

    /* new stuff */
    background-color:white;
    font-size:14px !important;
    height:26px;
    margin-bottom:8px;

    padding-top: $spacer / 2.4;
    padding-bottom: $spacer / 2.4;
    padding-right: ($spacer / 2);
    justify-content: flex-start;
    text-transform: none;
    border-radius: 0 22px 22px 0;
    width: 100%;
    font-weight: normal;
    transition: $transition-base;
    color: $blue;
    white-space: nowrap;

    &.active,
    &:hover {
      color: $primary;
      background: $gray-300;
    }
  }

  .app-sidebar-item {
    display: block;
    padding: 2px ($spacer) 1px 0;
  }

  .app-sidebar-button {

    padding-top: $spacer / 2.4;
    padding-bottom: $spacer / 2.4;
    padding-right: ($spacer / 2);
    justify-content: flex-start;
    text-transform: none;
    border-radius: 0 22px 22px 0;
    font-size: 14px;
    width: 100%;
    font-weight: normal;
    transition: $transition-base;
    color: $blue;
    white-space: nowrap;
    height: 30px;

    &.active-item,
    //&.active,
    &:hover {
      color: $primary;
      background: $gray-300;
    }
  }


  .app-sidebar-button-wrapper {
    padding: 4px 17px;
    justify-content: flex-start;
    text-transform: none;
    width: 100%;
    border-radius: 3px;
    transition: $transition-base;
    font-weight: normal;

    &.depth-0 {
      font-weight: 500;
    }

    font-size: 14px;
    color: $blue;

    &.active,
    &:hover {
      background: none;
      color: $primary;
    }
  }

  .app-sidebar-icon {
    color: theme-color($primary);
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .sidebar-expand-icon {
    margin-left: auto;
    height: 16px;
    width: 16px;
    opacity: .6;
    transition: $transition-base;
  }

  .sidebar-expand-icon-rotate {
    opacity: .9;
    transform: rotate(180deg);
  }

  .menu-item-label {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .active-item {
    color: $primary;
    font-weight: 500;
    .app-sidebar-icon {
      color: $primary;
    }
  }
}
