.superuser-icon path
{
  fill:#9B00D1;
}
.icon-light path{
  fill:#A9A9A9;
}
.badge-error-and-warning{
  background-image: linear-gradient(to right, #f83245 0%,#f83245 50%,#000000 50%,#f4772e 50%,#f4772e 100%);
}
.company-dark path{
  fill:#1B222A;
}

.device-online path{
  fill:#1bc943;
  stroke: #1bc943;
  stroke-opacity: 1;
}
